import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login, user } = useAuth();

  useEffect(() => {
    if (user) {
      if (user.role === 'customer') {
        navigate('/dashboard');
      } else {
        navigate('/admin/customers');
      }
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      if (user.role === 'customer') {
        navigate('/dashboard');
      } else {
        navigate('/admin/customers');
      }
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-[#fafafa] relative overflow-hidden">
      {/* Decorative elements */}
      <div className="absolute inset-0 z-0">
        <div className="absolute top-20 left-20 w-72 h-72 bg-blue-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob"></div>
        <div className="absolute top-40 right-20 w-72 h-72 bg-purple-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-2000"></div>
        <div className="absolute -bottom-8 left-40 w-72 h-72 bg-pink-100 rounded-full mix-blend-multiply filter blur-xl opacity-70 animate-blob animation-delay-4000"></div>
      </div>

      <div className="max-w-md w-full m-4 relative z-10">
        <div className="bg-white/80 backdrop-blur-lg rounded-2xl shadow-[0_8px_30px_rgb(0,0,0,0.06)] p-8 border border-white/60">
          <div className="flex flex-col items-center">
            <div className="relative group">
              <img 
                src="https://tetraagroup.com/assets/images/logo.png"
                alt="Tetraa Group"
                className="h-16 w-auto transform transition-transform duration-500 group-hover:scale-105"
              />
              <div className="absolute inset-x-0 h-0.5 bottom-0 bg-gradient-to-r from-[#233563] to-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-500" />
            </div>
            <h2 className="mt-6 text-3xl font-extrabold bg-clip-text text-transparent bg-gradient-to-r from-[#233563] to-blue-500">
              Welcome Back
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Sign in to access your licensed products
            </p>
          </div>

          <form className="mt-8 space-y-6" onSubmit={handleSubmit}>
            {error && (
              <div className="bg-red-50/50 backdrop-blur-sm text-red-600 p-4 rounded-xl text-center text-sm border border-red-200 shadow-sm">
                {error}
              </div>
            )}
            
            <div className="space-y-5">
              <div className="group">
                <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="block w-full px-4 py-3 border border-gray-200 rounded-xl text-gray-900 placeholder-gray-400 
                    focus:outline-none focus:ring-2 focus:ring-[#233563]/20 focus:border-[#233563] 
                    transition-all duration-300 ease-in-out backdrop-blur-sm bg-white/50
                    hover:border-[#233563]/40"
                  placeholder="Enter your email"
                />
              </div>

              <div className="group">
                <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-1">
                  Password
                </label>
                <input
                  id="password"
                  type="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="block w-full px-4 py-3 border border-gray-200 rounded-xl text-gray-900 placeholder-gray-400 
                    focus:outline-none focus:ring-2 focus:ring-[#233563]/20 focus:border-[#233563] 
                    transition-all duration-300 ease-in-out backdrop-blur-sm bg-white/50
                    hover:border-[#233563]/40"
                  placeholder="Enter your password"
                />
              </div>
            </div>

            <button
              type="submit"
              className="relative w-full py-3.5 px-4 border border-transparent text-sm font-medium rounded-xl text-white 
                overflow-hidden group focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#233563]
                transition-all duration-300"
            >
              <div className="absolute inset-0 w-full h-full transition-all duration-300 group-hover:scale-105">
                <div className="absolute inset-0 bg-gradient-to-r from-[#233563] to-blue-500"></div>
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-[#233563] opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
              </div>
              <span className="relative">Sign In</span>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;