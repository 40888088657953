import { useEffect, useState } from 'react';
import { getCustomerProducts } from '../services/api';
import CustomerProductCard from '../components/CustomerProductCard';
import { useAuth } from '../context/AuthContext';

const CustomerDashboardPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const data = await getCustomerProducts(user.id);
        setProducts(data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [user.id]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-white">
        <div className="relative">
          <div className="animate-spin rounded-full h-12 w-12 sm:h-16 sm:w-16 border-4 border-[#233563] border-t-transparent"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-6 w-6 sm:h-8 sm:w-8 bg-gradient-to-r from-[#233563] to-blue-500 rounded-full animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white backdrop-blur-lg">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-12">
        <div className="relative mb-8 sm:mb-12">
          <h1 className="text-3xl sm:text-4xl md:text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#233563] to-blue-500 py-2">
            Your Products
          </h1>
          <div className="absolute -bottom-4 left-0 h-1 w-24 sm:w-32 bg-gradient-to-r from-[#233563] to-blue-500 rounded-full"></div>
        </div>
        
        <div className="relative">
          <div className="absolute inset-0 bg-gradient-to-br from-[#233563]/5 to-blue-500/5 rounded-2xl sm:rounded-3xl transform -skew-y-3"></div>
          <div className="relative grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-8 p-4 sm:p-8">
            {products.length === 0 ? (
              <div className="col-span-full text-center py-8 sm:py-12">
                <p className="text-lg sm:text-xl text-gray-600">No products found</p>
              </div>
            ) : (
              products.map((product) => (
                <div key={product.id} className="transform hover:-translate-y-2 transition-all duration-300">
                  <CustomerProductCard product={product} />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomerDashboardPage;