import { useState, useEffect } from 'react';
import { createProductVersion, getProductVersions, deleteProductVersion, editProduct, editProductVersion, getLicenceTemplate } from '../services/api';
import { Pencil, Trash2, ChevronDown, Upload, Check, X, Info, Plus, Download } from 'lucide-react';
import { API_BASE_URL } from '../utils/constants';

const ProductVersionsCard = ({ product, onVersionChange, onDelete }) => {
  const [expanded, setExpanded] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [versions, setVersions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productName, setProductName] = useState(product.name);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingVersion, setEditingVersion] = useState(null);
  const [showLicenceInfo, setShowLicenceInfo] = useState(false);
  const [licenceTemplate, setLicenceTemplate] = useState([]);
  const [showEditProductModal, setShowEditProductModal] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showDeleteProductConfirmation, setShowDeleteProductConfirmation] = useState(false);
  const [versionToDelete, setVersionToDelete] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [editProductData, setEditProductData] = useState({
    name: product.name,
    licenceTemplate: product.licenceTemplate
  });
  const [versionData, setVersionData] = useState({
    version: '',
    description: '',
    setupFile: null,
    features: ''
  });
  useEffect(() => {
    if (expanded) {
      fetchVersions();
    }
  }, [expanded, product.id]);

  useEffect(() => {
    fetchLicenceTemplate();
  }, [product.id]);

  const fetchLicenceTemplate = async () => {
    try {
      const template = await getLicenceTemplate(product.id);
      setLicenceTemplate(Array.isArray(template) ? template : []);
    } catch (error) {
      console.error('Error fetching licence template:', error);
    }
  };

  const fetchVersions = async () => {
    try {
      setLoading(true);
      const versionsData = await getProductVersions(product.id);
      setVersions(versionsData);
    } catch (error) {
      console.error('Error fetching versions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditProduct = async () => {
    try {
      const template = await getLicenceTemplate(product.id);
      setEditProductData({
        name: productName,
        licenceTemplate: Array.isArray(template) ? template : []
      });
      setShowEditProductModal(true);
      setExpanded(true);
    } catch (error) {
      console.error('Error fetching licence template:', error);
    }
  };

  const handleSaveProduct = async () => {
    try {
      await editProduct(product.id, editProductData);
      setProductName(editProductData.name);
      setLicenceTemplate(editProductData.licenceTemplate);
      setShowEditProductModal(false);
    } catch (error) {
      console.error('Error updating product:', error);
    }
  };

  const handleEditVersion = (version) => {
    setEditingVersion(version);
    setVersionData({
      version: version.version,
      description: version.description,
      setupFile: null,
      features: version.features?.join(', ') || ''
    });
    setShowEditModal(true);
    setExpanded(true);
  };

  const handleUpdateVersion = async (e) => {
    e.preventDefault();
    try {
      const formData = {
        version: versionData.version,
        description: versionData.description,
        features: versionData.features ? versionData.features.split(',').map(f => f.trim()) : []
      };
      
      await editProductVersion(product.id, editingVersion.id, formData);
      setShowEditModal(false);
      setEditingVersion(null);
      setVersionData({
        version: '',
        description: '',
        setupFile: null,
        features: ''
      });
      await fetchVersions();
      if (onVersionChange) {
        onVersionChange();
      }
    } catch (error) {
      console.error('Error updating version:', error);
    }
  };

  const getTableHeaders = () => {
    if (!versions || !versions[0]) return [];
    return [...Object.keys(versions[0]).filter(key => key !== 'id' && key !== 'download'), 'actions'];
  };

  const formatHeader = (header) => {
    return header
      .replace(/([A-Z])/g, ' $1')
      .replace(/^./, str => str.toUpperCase())
      .trim();
  };

  const handleDeleteVersion = async (versionId) => {
    setVersionToDelete(versionId);
    setShowDeleteConfirmation(true);
  };

  const confirmDeleteVersion = async () => {
    try {
      await deleteProductVersion(product.id, versionToDelete);
      await fetchVersions();
      if (onVersionChange) {
        onVersionChange();
      }
    } catch (error) {
      console.error('Error deleting version:', error);
    } finally {
      setShowDeleteConfirmation(false);
      setVersionToDelete(null);
    }
  };

  const formatCellContent = (content, key, version) => {
    if (key === 'actions') {
      return (
        <div className="flex space-x-2">
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleEditVersion(version);
            }}
            className="p-1 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-100"
          >
            <Pencil size={16} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteVersion(version.id);
            }}
            className="p-1 text-red-600 hover:text-red-800 rounded-full hover:bg-red-100"
          >
            <Trash2 size={16} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = `${API_BASE_URL}/${version.download}?token=${localStorage.getItem('token')}`;
            }}
            className="p-1 text-[#233563] hover:text-[#1a284d] rounded-full hover:bg-blue-100"
          >
            <Download size={16} />
          </button>
        </div>
      );
    }
    if (Array.isArray(content)) {
      return content.join(' | ');
    }
    return content;
  };

  const handleInputChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      setVersionData(prev => ({
        ...prev,
        [name]: files[0]
      }));
    } else {
      setVersionData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleEditProductInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name.startsWith('licence.')) {
      const licenceField = name.split('.')[1];
      setEditProductData(prev => {
        const updatedLicenceTemplate = [...prev.licenceTemplate];
        updatedLicenceTemplate[index][licenceField] = value;
        return {
          ...prev,
          licenceTemplate: updatedLicenceTemplate
        };
      });
    } else {
      setEditProductData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleAddLicenceField = () => {
    setEditProductData(prev => ({
      ...prev,
      licenceTemplate: [...prev.licenceTemplate, { key: '', value: '' }]
    }));
  };

  const handleRemoveLicenceField = (index) => {
    setEditProductData(prev => ({
      ...prev,
      licenceTemplate: prev.licenceTemplate.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsUploading(true);
      setUploadProgress(0);
      
      const formData = new FormData();
      formData.append('description', versionData.description);
      formData.append('version', versionData.version);
      formData.append('setup_file', versionData.setupFile);
      formData.append('features', versionData.features);

      const xhr = new XMLHttpRequest();
      xhr.open('POST', `${API_BASE_URL}/products/${product.id}/versions`);
      xhr.setRequestHeader('Authorization', `Bearer ${localStorage.getItem('token')}`);
      xhr.setRequestHeader('accept', 'application/json');

      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const progress = (event.loaded / event.total) * 100;
          setUploadProgress(Math.round(progress));
        }
      };

      xhr.onload = async () => {
        if (xhr.status === 200 || xhr.status === 201) {
          setShowUploadModal(false);
          setVersionData({
            version: '',
            description: '',
            setupFile: null,
            features: ''
          });
          await fetchVersions();
          if (onVersionChange) {
            onVersionChange();
          }
        } else {
          throw new Error('Failed to create version');
        }
        setIsUploading(false);
        setUploadProgress(0);
      };

      xhr.onerror = () => {
        console.error('Error creating version');
        setIsUploading(false);
        setUploadProgress(0);
      };

      xhr.send(formData);
    } catch (error) {
      console.error('Error creating version:', error);
      setIsUploading(false);
      setUploadProgress(0);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow-md overflow-hidden">
      <div 
        className="p-4 flex justify-between items-center cursor-pointer hover:bg-gray-50"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="flex items-center space-x-4">
          <h2 className="text-xl font-semibold text-[#233563]">{productName}</h2>
          <button
            onClick={(e) => {
              e.stopPropagation();
              handleEditProduct();
            }}
            className="p-1 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-100"
          >
            <Pencil size={16} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowLicenceInfo(true);
              setExpanded(true);
            }}
            className="p-1 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-100"
          >
            <Info size={16} />
          </button>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setShowDeleteProductConfirmation(true);
            }}
            className="p-1 text-red-600 hover:text-red-800 rounded-full hover:bg-red-100"
          >
            <Trash2 size={16} />
          </button>
        </div>
        <ChevronDown 
          className={`w-6 h-6 transform transition-transform ${expanded ? 'rotate-180' : ''}`}
        />
      </div>
      
      {expanded && (
        <div className="p-4 border-t">
          <div className="mb-4 flex justify-end">
            <button
              onClick={() => setShowUploadModal(true)}
              className="px-4 py-2 bg-[#233563] text-white rounded-lg hover:bg-[#1a284d] transition-colors flex items-center space-x-2"
            >
              <Upload size={16} />
              <span>Upload New</span>
            </button>
          </div>

          {loading ? (
            <div className="flex justify-center py-4">
              <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-[#233563]"></div>
            </div>
          ) : (
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {getTableHeaders().map((header) => (
                    <th 
                      key={header}
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      {formatHeader(header)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {versions.map((version) => (
                  <tr key={version.id}>
                    {getTableHeaders().map((key) => (
                      <td key={`${version.id}-${key}`} className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatCellContent(version[key], key, version)}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          {showUploadModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
              <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-xl font-bold mb-4">Upload New Version</h2>
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Version</label>
                      <input
                        type="text"
                        name="version"
                        value={versionData.version}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                      <input
                        type="text"
                        name="description"
                        value={versionData.description}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Setup File</label>
                      <input
                        type="file"
                        name="setupFile"
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        accept=".exe,.msi,.zip"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Features (comma-separated)
                      </label>
                      <input
                        type="text"
                        name="features"
                        value={versionData.features}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        placeholder="Feature 1, Feature 2, Feature 3"
                      />
                    </div>
                    {isUploading && (
                      <div className="w-full bg-gray-200 rounded-full h-2.5">
                        <div 
                          className="bg-[#233563] h-2.5 rounded-full transition-all duration-300"
                          style={{ width: `${uploadProgress}%` }}
                        ></div>
                      </div>
                    )}
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        onClick={() => setShowUploadModal(false)}
                        className="px-4 py-2 text-gray-600 hover:text-gray-800"
                        disabled={isUploading}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={`px-4 py-2 bg-[#233563] text-white rounded-lg ${isUploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-[#1a284d]'}`}
                        disabled={isUploading}
                      >
                        {isUploading ? 'Uploading...' : 'Upload Version'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {showEditModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-xl font-bold mb-4">Edit Version</h2>
                <form onSubmit={handleUpdateVersion}>
                  <div className="grid grid-cols-1 gap-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Version</label>
                      <input
                        type="text"
                        name="version"
                        value={versionData.version}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">Description</label>
                      <input
                        type="text"
                        name="description"
                        value={versionData.description}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        required
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Features (comma-separated)
                      </label>
                      <input
                        type="text"
                        name="features"
                        value={versionData.features}
                        onChange={handleInputChange}
                        className="w-full px-3 py-2 border border-gray-300 rounded-md"
                        placeholder="Feature 1, Feature 2, Feature 3"
                      />
                    </div>
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        onClick={() => {
                          setShowEditModal(false);
                          setEditingVersion(null);
                        }}
                        className="px-4 py-2 text-gray-600 hover:text-gray-800"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-4 py-2 bg-[#233563] text-white rounded-lg hover:bg-[#1a284d]"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}

          {showLicenceInfo && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-xl font-bold mb-4">Licence Information</h2>
                <div className="space-y-4">
                  {Array.isArray(licenceTemplate) && licenceTemplate.map((item, index) => (
                    <div key={index} className="flex items-center gap-2">
                      <span className="text-sm font-bold text-gray-900 uppercase">{item.key}:</span>
                      <span className="text-gray-600">{item.value}</span>
                    </div>
                  ))}
                </div>
                <div className="mt-6 flex justify-end">
                  <button
                    onClick={() => setShowLicenceInfo(false)}
                    className="px-4 py-2 bg-[#233563] text-white rounded-lg hover:bg-[#1a284d]"
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          )}

          {showEditProductModal && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg w-96 max-h-[80vh] overflow-y-auto">
                <h2 className="text-xl font-bold mb-4">Edit Product</h2>
                <div className="grid grid-cols-1 gap-4">
                  <div>
                    <label className="block text-sm font-medium text-gray-700 mb-1">Product Name</label>
                    <input
                      type="text"
                      name="name"
                      value={editProductData.name}
                      onChange={handleEditProductInputChange}
                      className="w-full px-3 py-2 border border-gray-300 rounded-md"
                      required
                    />
                  </div>
                  <div className="mb-4">
                    <div className="flex justify-between items-center mb-2">
                      <label className="block text-sm font-medium text-gray-700">
                        License Template
                      </label>
                      <button
                        type="button"
                        onClick={handleAddLicenceField}
                        className="flex items-center text-sm text-[#233563] hover:text-[#1a284d]"
                      >
                        <Plus size={16} className="mr-1" /> Add Field
                      </button>
                    </div>
                    
                    {Array.isArray(editProductData.licenceTemplate) && editProductData.licenceTemplate.map((field, index) => (
                      <div key={index} className="flex gap-2 mb-2">
                        <input
                          type="text"
                          placeholder="Key"
                          name={`licence.key`}
                          value={field.key}
                          onChange={(e) => handleEditProductInputChange(e, index)}
                          className="w-1/2 px-3 py-2 border border-gray-300 rounded-md"
                          required
                        />
                        <input
                          type="text"
                          placeholder="Value"
                          name={`licence.value`}
                          value={field.value}
                          onChange={(e) => handleEditProductInputChange(e, index)}
                          className="w-1/2 px-3 py-2 border border-gray-300 rounded-md"
                          required
                        />
                        {editProductData.licenceTemplate.length > 1 && (
                          <button
                            type="button"
                            onClick={() => handleRemoveLicenceField(index)}
                            className="p-2 text-gray-500 hover:text-red-500"
                          >
                            <Trash2 size={16} />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="flex justify-end space-x-3">
                    <button
                      type="button"
                      onClick={() => setShowEditProductModal(false)}
                      className="px-4 py-2 text-gray-600 hover:text-gray-800"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSaveProduct}
                      className="px-4 py-2 bg-[#233563] text-white rounded-lg hover:bg-[#1a284d]"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {showDeleteConfirmation && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg w-96">
                <h2 className="text-xl font-bold mb-4">Delete Version</h2>
                <p className="text-gray-600 mb-6">Are you sure you want to delete this version? This action cannot be undone.</p>
                <div className="flex justify-end space-x-3">
                  <button
                    onClick={() => setShowDeleteConfirmation(false)}
                    className="px-4 py-2 text-gray-600 hover:text-gray-800"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={confirmDeleteVersion}
                    className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}

      {showDeleteProductConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg w-96">
            <h2 className="text-xl font-bold mb-4">Delete Product</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this product and all its versions? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteProductConfirmation(false)}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  onDelete();
                  setShowDeleteProductConfirmation(false);
                }}
                className="px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700"
              >
                Delete Product
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductVersionsCard;
