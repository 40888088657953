import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { useState } from 'react';
import { Lock, Menu, X } from 'lucide-react';
import { editCustomer } from '../services/api';

const Navbar = () => {
  const { user, logout } = useAuth();
  const location = useLocation();
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
    try {
      await editCustomer(user.id, { password: newPassword });
      setShowPasswordModal(false);
      setNewPassword('');
      setConfirmPassword('');
      setError('');
    } catch (error) {
      setError('Failed to update password');
    }
  };

  return (
    <>
      <nav className="bg-gradient-to-r from-white to-gray-50 backdrop-blur-lg border-b border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6">
          <div className="flex justify-between h-20">
            <div className="flex items-center">
              <Link to="/" className="relative group">
                <div className="flex items-center gap-3 transition-transform duration-300 transform group-hover:scale-105">
                  <img src="https://tetraagroup.com/assets/images/logo.png" alt="Tetraa Group" className="h-8 sm:h-10 drop-shadow-lg" />
                  <span className="text-lg sm:text-xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#233563] to-blue-500">
                    | {user?.role === 'admin' ? 'Admin Portal' : 'Customer Portal'}
                  </span>
                </div>
                <div className="absolute inset-x-0 h-0.5 bottom-0 bg-gradient-to-r from-[#233563] to-blue-500 scale-x-0 group-hover:scale-x-100 transition-transform duration-300" />
              </Link>
              
              {/* Desktop Navigation */}
              {user && user.role === 'admin' && (
                <div className="hidden md:flex ml-10 items-center space-x-6">
                  <Link
                    to="/admin/customers"
                    className={`relative px-4 py-2.5 rounded-xl text-sm font-semibold transition-all duration-300 ${
                      location.pathname === '/admin/customers'
                        ? 'bg-gradient-to-r from-[#233563] to-blue-500 text-white shadow-lg shadow-blue-500/20'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-[#233563]'
                    }`}
                  >
                    Customers
                  </Link>
                  <Link
                    to="/admin/products"
                    className={`relative px-4 py-2.5 rounded-xl text-sm font-semibold transition-all duration-300 ${
                      location.pathname === '/admin/products'
                        ? 'bg-gradient-to-r from-[#233563] to-blue-500 text-white shadow-lg shadow-blue-500/20'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-[#233563]'
                    }`}
                  >
                    Products
                  </Link>
                </div>
              )}
            </div>

            {/* Mobile menu button */}
            {user && location.pathname !== '/login' && (
              <div className="md:hidden flex items-center">
                <button
                  onClick={() => setIsMenuOpen(!isMenuOpen)}
                  className="p-2 rounded-md text-gray-700 hover:text-[#233563] hover:bg-gray-50"
                >
                  {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                </button>
              </div>
            )}

            {/* Desktop user menu */}
            {user && (
              <div className="hidden md:flex items-center space-x-6">
                <span className="text-gray-700 font-medium bg-gray-50 px-4 py-2 rounded-xl border border-gray-100">
                  {user.email}
                </span>
                <button
                  onClick={() => setShowPasswordModal(true)}
                  className="p-2 text-gray-700 hover:text-[#233563] hover:bg-gray-50 rounded-xl transition-colors"
                  title="Reset Password"
                >
                  <Lock size={20} />
                </button>
                <button
                  onClick={logout}
                  className="relative overflow-hidden px-6 py-2.5 rounded-xl font-semibold text-white
                    bg-gradient-to-r from-[#233563] to-blue-500 hover:from-[#1a284d] hover:to-blue-600
                    transform hover:scale-105 transition-all duration-300 shadow-lg hover:shadow-xl
                    hover:shadow-blue-500/20 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#233563]"
                >
                  <span className="relative z-10">Logout</span>
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-600 to-[#1a284d] transform scale-x-0 transition-transform duration-300 origin-left hover:scale-x-100" />
                </button>
              </div>
            )}
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden border-t border-gray-100 py-4 px-4 transition-all duration-300 ${isMenuOpen ? 'block' : 'hidden'}`}>
          {user && (
            <>
              {user.role === 'admin' && (
                <div className="space-y-2 mb-4">
                  <Link
                    to="/admin/customers"
                    className={`block px-4 py-2.5 rounded-xl text-sm font-semibold transition-all duration-300 ${
                      location.pathname === '/admin/customers'
                        ? 'bg-gradient-to-r from-[#233563] to-blue-500 text-white'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-[#233563]'
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Customers
                  </Link>
                  <Link
                    to="/admin/products"
                    className={`block px-4 py-2.5 rounded-xl text-sm font-semibold transition-all duration-300 ${
                      location.pathname === '/admin/products'
                        ? 'bg-gradient-to-r from-[#233563] to-blue-500 text-white'
                        : 'text-gray-700 hover:bg-gray-50 hover:text-[#233563]'
                    }`}
                    onClick={() => setIsMenuOpen(false)}
                  >
                    Products
                  </Link>
                </div>
              )}
              <div className="space-y-2">
                <div className="text-gray-700 font-medium bg-gray-50 px-4 py-2 rounded-xl border border-gray-100">
                  {user.email}
                </div>
                <button
                  onClick={() => {
                    setShowPasswordModal(true);
                    setIsMenuOpen(false);
                  }}
                  className="w-full flex items-center gap-2 px-4 py-2 text-gray-700 hover:text-[#233563] hover:bg-gray-50 rounded-xl transition-colors"
                >
                  <Lock size={20} />
                  <span>Reset Password</span>
                </button>
                <button
                  onClick={logout}
                  className="w-full px-4 py-2.5 rounded-xl font-semibold text-white bg-gradient-to-r from-[#233563] to-blue-500 hover:from-[#1a284d] hover:to-blue-600"
                >
                  Logout
                </button>
              </div>
            </>
          )}
        </div>
      </nav>

      {showPasswordModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4" style={{zIndex: 1000}}>
          <div className="bg-white p-6 rounded-xl w-[400px] max-w-full">
            <h2 className="text-xl font-bold mb-4">Reset Password</h2>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            <form onSubmit={handlePasswordReset} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">New Password</label>
                <input
                  type="password"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#233563]"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">Confirm Password</label>
                <input
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-[#233563]"
                  required
                />
              </div>
              <div className="flex justify-end gap-2">
                <button
                  type="button"
                  onClick={() => {
                    setShowPasswordModal(false);
                    setNewPassword('');
                    setConfirmPassword('');
                    setError('');
                  }}
                  className="px-4 py-2 text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-[#233563] text-white rounded-lg hover:bg-[#1a284d]"
                >
                  Update Password
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default Navbar;