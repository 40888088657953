import { API_BASE_URL } from '../utils/constants';
import { Download, Calendar, Globe, Tag, Clock } from 'lucide-react';

const CustomerProductCard = ({ product }) => {
  const handleDownload = () => {
    window.location.href = `${API_BASE_URL}/${product.version.download}?token=${localStorage.getItem('token')}`;
  };

  return (
    <div className="bg-gradient-to-br from-white to-gray-50 rounded-2xl shadow-lg p-8 hover:shadow-2xl transition-all duration-300 border border-gray-100 backdrop-blur-sm">
      <div className="relative">
        <div className="absolute -top-5 -right-4 bg-blue-500 text-white text-xs font-bold px-3 py-1 rounded-full">
          v{product.version.version}
        </div>
        <h3 className="text-2xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-[#233563] to-blue-500">
          {product.productName}
        </h3>
      </div>

      <div className="mt-6 space-y-4">
        <div className="flex items-center space-x-3 text-gray-700">
          <Globe className="w-5 h-5 text-blue-500" />
          <p>{product.site}</p>
        </div>
        
        <div className="flex items-center space-x-3 text-gray-700">
          <Tag className="w-5 h-5 text-green-500" />
          <p>Purchased: {new Date(product.purchaseDate).toLocaleDateString()}</p>
        </div>
        
        <div className="flex items-center space-x-3 text-gray-700">
          <Clock className="w-5 h-5 text-red-500" />
          <p>Expires: {product.licenceExpiryDate ? new Date(product.licenceExpiryDate).toLocaleDateString() : 'N/A'}</p>
        </div>

        {product.subscriptionInfo && (
          <div className="mt-4 p-4 bg-blue-50 rounded-xl space-y-2">
            {product.subscriptionInfo.map((info, index) => (
              <div key={index} className="flex items-center justify-between">
                <span className="text-sm font-medium text-gray-600">{info.key}</span>
                <span className="text-sm font-bold text-[#233563]">{info.value}</span>
              </div>
            ))}
          </div>
        )}

        <button
          onClick={handleDownload}
          className="mt-6 w-full flex justify-center items-center py-3 px-6 rounded-xl text-white font-medium
          bg-gradient-to-r from-[#233563] to-blue-500 hover:from-[#1a284d] hover:to-blue-600
          transform hover:scale-[1.02] transition-all duration-300 shadow-lg hover:shadow-xl"
        >
          <Download className="w-5 h-5 mr-2" />
          Download Application
        </button>
      </div>
    </div>
  );
};

export default CustomerProductCard;