import { useEffect, useState } from 'react';
import { getProducts, createProduct, deleteProduct } from '../services/api';
import { useAuth } from '../context/AuthContext';
import ProductVersionsCard from '../components/ProductVersionsCard';
import { Plus, Trash2, X } from 'lucide-react';

const AdminProductDashboardPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newProductName, setNewProductName] = useState('');
  const [licenceTemplate, setlicenceTemplate] = useState([
    { key: '', value: '' }
  ]);
  const { user } = useAuth();

  const fetchProducts = async () => {
    try {
      const productsData = await getProducts(user.id);
      setProducts(productsData);
    } catch (error) {
      console.error('Error fetching products:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [user.id]);

  const handleCreateProduct = async (e) => {
    e.preventDefault();
    try {
      const newProduct = await createProduct({ 
        name: newProductName,
        licenceTemplate: licenceTemplate
      });
      setProducts([...products, newProduct]);
      setNewProductName('');
      setlicenceTemplate([{ key: '', value: '' }]);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error creating product:', error);
    }
  };

  const handleDeleteProduct = async (productId) => {
    try {
      await deleteProduct(productId);
      setProducts(products.filter(product => product.id !== productId));
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };

  const handleAddLicenseField = () => {
    setlicenceTemplate([...licenceTemplate, { key: '', value: '' }]);
  };

  const handleRemoveLicenseField = (index) => {
    setlicenceTemplate(licenceTemplate.filter((_, i) => i !== index));
  };

  const handlelicenceTemplateChange = (index, field, value) => {
    const newTemplate = [...licenceTemplate];
    newTemplate[index][field] = value;
    setlicenceTemplate(newTemplate);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-white">
        <div className="relative">
          <div className="animate-spin rounded-full h-12 w-12 sm:h-16 sm:w-16 border-4 border-[#233563] border-t-transparent"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-6 w-6 sm:h-8 sm:w-8 bg-gradient-to-r from-[#233563] to-blue-500 rounded-full animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-6 sm:mb-8">
          <div className="relative">
            <h1 className="text-3xl sm:text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#233563] to-blue-500">
              Products
            </h1>
            <div className="absolute -bottom-2 left-0 h-1 w-16 sm:w-24 bg-gradient-to-r from-[#233563] to-blue-500 rounded-full"></div>
          </div>
          <button
            onClick={() => setIsModalOpen(true)}
            className="w-full sm:w-auto px-6 py-2.5 bg-gradient-to-r from-[#233563] to-blue-500 text-white rounded-xl font-semibold 
              hover:from-[#1a284d] hover:to-blue-600 transform hover:scale-105 transition-all duration-300 
              shadow-lg hover:shadow-xl hover:shadow-blue-500/20 focus:outline-none focus:ring-2 
              focus:ring-offset-2 focus:ring-[#233563]"
          >
            <span className="flex items-center justify-center gap-2">
              <Plus size={20} />
              Create New Product
            </span>
          </button>
        </div>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 p-4 overflow-y-auto">
            <div className="min-h-screen flex items-center justify-center">
              <div className="bg-white w-full max-w-[600px] rounded-2xl shadow-2xl">
                <div className="p-6">
                  <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-[#233563]">Create New Product</h2>
                    <button
                      onClick={() => {
                        setIsModalOpen(false);
                        setlicenceTemplate([{ key: '', value: '' }]);
                      }}
                      className="p-2 hover:bg-gray-100 rounded-xl transition-colors"
                    >
                      <X size={24} />
                    </button>
                  </div>
                  
                  <form onSubmit={handleCreateProduct} className="space-y-6">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Product Name
                      </label>
                      <input
                        type="text"
                        value={newProductName}
                        onChange={(e) => setNewProductName(e.target.value)}
                        className="w-full px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
                        required
                      />
                    </div>

                    <div>
                      <div className="flex justify-between items-center mb-2">
                        <label className="block text-sm font-medium text-gray-700">
                          License Template
                        </label>
                        <button
                          type="button"
                          onClick={handleAddLicenseField}
                          className="flex items-center text-sm text-[#233563] hover:text-[#1a284d] px-3 py-1.5 
                            hover:bg-gray-100 rounded-lg transition-colors"
                        >
                          <Plus size={16} className="mr-1" /> Add Field
                        </button>
                      </div>
                      
                      <div className="space-y-3">
                        {licenceTemplate.map((field, index) => (
                          <div key={index} className="flex flex-col sm:flex-row gap-2">
                            <input
                              type="text"
                              placeholder="Key"
                              value={field.key}
                              onChange={(e) => handlelicenceTemplateChange(index, 'key', e.target.value)}
                              className="flex-1 px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
                            />
                            <input
                              type="text"
                              placeholder="Default Value"
                              value={field.value}
                              onChange={(e) => handlelicenceTemplateChange(index, 'value', e.target.value)}
                              className="flex-1 px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
                            />
                            {licenceTemplate.length > 1 && (
                              <button
                                type="button"
                                onClick={() => handleRemoveLicenseField(index)}
                                className="sm:p-2 p-2.5 text-gray-500 hover:text-red-500 hover:bg-red-50 rounded-xl transition-colors"
                              >
                                <Trash2 size={20} />
                              </button>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="flex flex-col-reverse sm:flex-row justify-end gap-3 pt-4">
                      <button
                        type="button"
                        onClick={() => {
                          setIsModalOpen(false);
                          setlicenceTemplate([{ key: '', value: '' }]);
                        }}
                        className="px-6 py-2.5 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-xl transition-colors"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="px-6 py-2.5 bg-gradient-to-r from-[#233563] to-blue-500 text-white rounded-xl
                          hover:from-[#1a284d] hover:to-blue-600 transform hover:scale-105 transition-all duration-300
                          shadow-lg hover:shadow-xl hover:shadow-blue-500/20"
                      >
                        Create Product
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="space-y-4">
          {products.map((product) => (
            <ProductVersionsCard 
              key={product.id} 
              product={product} 
              onDelete={() => handleDeleteProduct(product.id)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminProductDashboardPage;
