import { useState, useEffect } from 'react';
import { getCustomers, createCustomer, deleteCustomer } from '../services/api';
import CustomerProductVersionsCard from '../components/CustomerProductVersionsCard';
import { Plus, Copy, X, Shuffle, Eye, EyeOff, UserPlus, Users } from 'lucide-react';

const AdminCustomerDashboardPage = () => {
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newCustomerName, setNewCustomerName] = useState('');
  const [newCustomerEmail, setNewCustomerEmail] = useState('');
  const [newCustomerPassword, setNewCustomerPassword] = useState('');
  const [newCustomerRole, setNewCustomerRole] = useState('customer');
  const [searchTerm, setSearchTerm] = useState('');
  const [lastCreatedInfo, setLastCreatedInfo] = useState(null);
  const [showCopied, setShowCopied] = useState(false);
  const [showAdmins, setShowAdmins] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    try {
      setLoading(true);
      const customersData = await getCustomers();
      setCustomers(customersData);
    } catch (error) {
      console.error('Error fetching customers:', error);
    } finally {
      setLoading(false);
    }
  };

  const generateRandomPassword = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*';
    let password = '';
    for (let i = 0; i < 12; i++) {
      password += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return password;
  };

  const handleAddCustomer = async (e) => {
    e.preventDefault();
    try {
      const customerData = {
        name: newCustomerName,
        email: newCustomerEmail,
        password: newCustomerPassword || generateRandomPassword(),
        role: newCustomerRole
      };
      const newCustomer = await createCustomer(customerData);
      setLastCreatedInfo({
        name: customerData.name,
        email: customerData.email,
        password: customerData.password
      });
      setShowAddModal(false);
      setNewCustomerName('');
      setNewCustomerEmail('');
      setNewCustomerPassword('');
      setNewCustomerRole('customer');
      await fetchCustomers();
    } catch (error) {
      console.error('Error creating customer:', error);
    }
  };

  const handleDeleteCustomer = async (customerId) => {
    try {
      await deleteCustomer(customerId);
      setCustomers(customers.filter(customer => customer.id !== customerId));
    } catch (error) {
      console.error('Error deleting customer:', error);
    }
  };

  const copyCustomerInfo = () => {
    if (!lastCreatedInfo) return;
    const text = `Name: ${lastCreatedInfo.name}\nEmail: ${lastCreatedInfo.email}\nPassword: ${lastCreatedInfo.password}`;
    navigator.clipboard.writeText(text);
    setShowCopied(true);
    setTimeout(() => setShowCopied(false), 2000);
  };

  const filteredCustomers = customers.filter(customer =>
    customer.name.toLowerCase().includes(searchTerm.toLowerCase()) && 
    (showAdmins || customer.role !== 'admin')
  );

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-gray-50 to-white">
        <div className="relative">
          <div className="animate-spin rounded-full h-12 w-12 sm:h-16 sm:w-16 border-4 border-[#233563] border-t-transparent"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-6 w-6 sm:h-8 sm:w-8 bg-gradient-to-r from-[#233563] to-blue-500 rounded-full animate-pulse"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={`fixed inset-0 ${showAddModal ? 'bg-black bg-opacity-50 z-50' : 'pointer-events-none'}`} />

      {showAddModal && (
        <div className="fixed inset-0 flex items-center justify-center p-4 z-50">
          <div className="bg-white p-6 rounded-2xl w-full max-w-[500px] max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-2xl font-bold text-[#233563]">Add New Customer</h2>
              <button
                onClick={() => {
                  setShowAddModal(false);
                  setNewCustomerName('');
                  setNewCustomerEmail('');
                  setNewCustomerPassword('');
                  setNewCustomerRole('customer');
                }}
                className="p-2 hover:bg-gray-100 rounded-xl transition-colors"
              >
                <X size={20} />
              </button>
            </div>
            <form onSubmit={handleAddCustomer} className="space-y-4">
              <div>
                <input
                  type="text"
                  value={newCustomerName}
                  onChange={(e) => setNewCustomerName(e.target.value)}
                  placeholder="Enter customer name"
                  className="w-full px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  value={newCustomerEmail}
                  onChange={(e) => setNewCustomerEmail(e.target.value)}
                  placeholder="Enter customer email"
                  className="w-full px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
                  required
                />
              </div>
              <div className="flex gap-2">
                <input
                  type="text"
                  value={newCustomerPassword}
                  onChange={(e) => setNewCustomerPassword(e.target.value)}
                  placeholder="Enter password (or leave empty for random)"
                  className="flex-1 px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
                />
                <button
                  type="button"
                  onClick={() => setNewCustomerPassword(generateRandomPassword())}
                  className="px-4 py-2.5 bg-gray-100 text-gray-600 rounded-xl hover:bg-gray-200 transition-colors flex items-center gap-2"
                  title="Generate random password"
                >
                  <Shuffle size={18} />
                  <span className="hidden sm:inline">Generate</span>
                </button>
              </div>
              <div>
                <select
                  value={newCustomerRole}
                  onChange={(e) => setNewCustomerRole(e.target.value)}
                  className="w-full px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
                >
                  <option value="customer">Customer</option>
                  <option value="admin">Admin</option>
                </select>
              </div>
              <div className="flex flex-col-reverse sm:flex-row justify-end gap-3 pt-4">
                <button
                  type="button"
                  onClick={() => {
                    setShowAddModal(false);
                    setNewCustomerName('');
                    setNewCustomerEmail('');
                    setNewCustomerPassword('');
                    setNewCustomerRole('customer');
                  }}
                  className="px-6 py-2.5 text-gray-600 hover:text-gray-800 hover:bg-gray-100 rounded-xl transition-colors"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-6 py-2.5 bg-gradient-to-r from-[#233563] to-blue-500 text-white rounded-xl
                    hover:from-[#1a284d] hover:to-blue-600 transform hover:scale-105 transition-all duration-300
                    shadow-lg hover:shadow-xl hover:shadow-blue-500/20"
                >
                  Add Customer
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-12">
          <div className="relative mb-8 sm:mb-12 flex justify-between items-center">
            <div>
              <h1 className="text-3xl sm:text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-[#233563] to-blue-500">
                Customer Management
              </h1>
              <div className="absolute -bottom-4 left-0 h-1 w-24 sm:w-32 bg-gradient-to-r from-[#233563] to-blue-500 rounded-full"></div>
            </div>
            <div className="flex items-center gap-4">
              <button
                onClick={() => setShowAdmins(!showAdmins)}
                className="px-4 py-2.5 bg-gray-100 text-gray-600 rounded-xl hover:bg-gray-200 transition-colors flex items-center gap-2"
                title={showAdmins ? "Hide Admins" : "Show Admins"}
              >
                {showAdmins ? <EyeOff size={20} /> : <Eye size={20} />}
                <span className="hidden sm:inline">{showAdmins ? "Hide Admins" : "Show Admins"}</span>
              </button>
              <button
                onClick={() => setShowAddModal(true)}
                className="px-4 sm:px-6 py-2.5 bg-gradient-to-r from-[#233563] to-blue-500 text-white rounded-xl
                  hover:from-[#1a284d] hover:to-blue-600 transform hover:scale-105 transition-all duration-300
                  shadow-lg hover:shadow-xl hover:shadow-blue-500/20 flex items-center gap-2"
              >
                <UserPlus size={20} className="block sm:hidden" />
                <Plus size={20} className="hidden sm:block" />
                <span className="hidden sm:inline">Add Customer</span>
              </button>
            </div>
          </div>

          <div className="mb-6">
            <input
              type="text"
              placeholder="Search customers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full px-4 py-2.5 border border-gray-300 rounded-xl focus:outline-none focus:ring-2 focus:ring-[#233563]"
            />
          </div>

          {lastCreatedInfo && (
            <div className="mb-6 p-6 bg-gradient-to-r from-green-50 to-blue-50 rounded-2xl border border-green-200/50 shadow-lg">
              <div className="flex flex-col sm:flex-row justify-between items-start gap-4">
                <div className="w-full">
                  <p className="font-semibold text-lg mb-3 text-[#233563]">Last Created Customer Info:</p>
                  <div className="space-y-2 break-all text-gray-700">
                    <p>Name: {lastCreatedInfo.name}</p>
                    <p>Email: {lastCreatedInfo.email}</p>
                    <p>Password: {lastCreatedInfo.password}</p>
                  </div>
                </div>
                <div className="flex items-center gap-2 self-end sm:self-start">
                  <div className="relative">
                    <button
                      onClick={copyCustomerInfo}
                      className="p-2 hover:bg-green-100/50 rounded-xl transition-colors"
                      title="Copy customer info"
                    >
                      <Copy size={20} />
                    </button>
                    {showCopied && (
                      <div className="absolute -bottom-8 right-0 bg-gray-800 text-white px-3 py-1 rounded-lg text-sm whitespace-nowrap">
                        Copied!
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => setLastCreatedInfo(null)}
                    className="p-2 hover:bg-green-100/50 rounded-xl transition-colors"
                    title="Close"
                  >
                    <X size={20} />
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="space-y-4">
            {filteredCustomers.map(customer => (
              <div key={customer.id}>
                <CustomerProductVersionsCard
                  customer={customer}
                  onDelete={() => handleDeleteCustomer(customer.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminCustomerDashboardPage;
